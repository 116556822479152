import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
    StyledNoPolicyText,
    StyledContainer,
    StyledEnrolmentText,
    StyledInsuranceContainer,
    StyledSpan,
    StyledText
} from './styles';
import { ReduxState } from '../../../../../redux';
import { fetchTopUpAvailabiltyStatus, setCurrentlySelectedPolicy } from '../../../../../redux/slices/EnrolmentSlice';
import { IPolicy } from '../../../../../redux/slices/PolicyListSlice/types';
import {
    insuranceCardDateFormat,
    getDaysLeft,
    getPolicyCardTrackObject,
    getSumInsured,
    getAvailableTopup,
    checkIfTopUpAvailable
} from '../../../../../utils/common';
import { ErrorContainer, Label, Loader } from '../../../../atoms';
import { InsuranceCard } from '../../../../containers';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import useSegment from '../../../../../utils/hooks/useSegment';
import { fetchUserPolicyList } from '../../../../../redux/slices/PolicyListSlice';
import { fetchUserDependents } from '../../../../../redux/slices/PolicyListSlice/thunks';
import { resetOrderState } from '../../../../../topup-src/redux/slices/PolicyListSlice';
import {
    checkForShowSuperTopupCardCondition,
    checkTopUpAvailableCompany,
    getEnrolledPolicyCompany,
    getGMCPolicyCompany,
    getSuperTopUpPolicyCompany,
    showPolicyMsgDashboard
} from '../../../../../utils/PolicyServices';

const sortPolicies = (list: IPolicy[]): IPolicy[] => {
    const overduePolicies: IPolicy[] = [];
    const notOverduePolicies: IPolicy[] = [];
    list.forEach((p) =>
        getDaysLeft(p.enrolmentDueDate) !== 'OVERDUE' ? notOverduePolicies.push(p) : overduePolicies.push(p)
    );
    return notOverduePolicies.concat(overduePolicies);
};
const DashboardPage: React.FunctionComponent = () => {
    const trackClick = useSegment('click');
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state: ReduxState) => state.policyList.policyList.loading);
    const error = useSelector((state: ReduxState) => state.policyList.policyList.error);
    const policyList: IPolicy[] = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const sortPolicyList = sortPolicies(policyList);
    const enrolPendingPolicyList = sortPolicyList.filter(
        (policy) => policy.enrolmentStatus?.toLowerCase() !== 'enroled'
    );
    const enrolledDonePolicyList = sortPolicyList.filter(
        (policy) => policy.enrolmentStatus?.toLowerCase() === 'enroled'
    );
    const policiesLeft = policyList.length - enrolledDonePolicyList.length;
    const userId = useSelector((state: ReduxState) => state.user?.userData?.data?.userId);
    const checkSuperTopUpAvailable = checkTopUpAvailableCompany();
    const superTopUpPolicy = getSuperTopUpPolicyCompany();
    const getGMCPolicy = getGMCPolicyCompany(policyList);
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);
    const superTopUpAvailable = checkIfTopUpAvailable(checkSuperTopUpAvailable, getGMCPolicy);
    const checkShowSuperTopUpCard = checkForShowSuperTopupCardCondition(policyList, superTopUpAvailable);
    const redirectToEnrolment = (policy: IPolicy) => {
        dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: policy }));
        history.push(INTERNAL_ROUTES.enrolment, { previousUrl: location.pathname });
    };
    const pendingGMCPolicy = () =>
        policyList
            ? policyList.filter(
                (policy: IPolicy) => policy.policyType === 'GMC' && policy.enrolmentStatus === 'PENDING'
            )
            : [];
    React.useEffect(() => {
        if (userId) {
            dispatch(fetchUserPolicyList({ userId }));
            dispatch(fetchUserDependents({ userId }));
            dispatch(fetchTopUpAvailabiltyStatus({ userId }));
            dispatch(resetOrderState());
        }
    }, [userId]);
    const handleCardClick = (policy: IPolicy) => {
        trackClick(getPolicyCardTrackObject(policy));
        if (policy?.policyType === 'SUPER_TOP_UP') {
            // TODO: Enrolment Status to be handled in backend. handling null for now
            if (checkSuperTopUpAvailable === 'NOT_SELECTED' || policy?.enrolmentStatus != 'ENROLED') {
                history.push(INTERNAL_ROUTES.topup, { previousUrl: location.pathname });
            }
            // if (superTopupStatus === 'NOT_SELECTED' || policy?.enrolmentStatus === 'NOT_SELECTED') {
            //     history.push(INTERNAL_ROUTES.topup);
            // } else if (policy?.enrolmentStatus === 'PENDING') {
            //     history.push(INTERNAL_ROUTES.topup);
            //     // changed as per discussion with Vasu
            //     // history.push(INTERNAL_ROUTES.checkout, { makeCalculatePremiumApiCall: true });
            // }
            if (getEnrolledGMCPolicies.length) {
                const enroledPolicy = { ...{}, ...getEnrolledGMCPolicies[0] };
                enroledPolicy.enrolmentStatus = 'PENDING';
                dispatch(setCurrentlySelectedPolicy({ currentlySelectedPolicy: enroledPolicy }));
            }
            return;
        }
        if (!(getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE')) {
            redirectToEnrolment(policy);
        }
    };
    return (
        <StyledContainer>
            {isLoading ? (
                <Loader />
            ) : error?.message.length ? (
                <ErrorContainer message={error.message} />
            ) : (
                <>
                    {policiesLeft > 0 || checkShowSuperTopUpCard ? (
                        <>
                            <StyledEnrolmentText $isDone={false}>
                                <StyledSpan>Enrolment Pending Policies</StyledSpan>
                                &nbsp;&nbsp;
                                <Label isActive={false}>
                                    {policiesLeft ? policiesLeft : checkShowSuperTopUpCard ? 1 : 0} LEFT
                                </Label>
                            </StyledEnrolmentText>
                            <StyledText>Enrol in the policies before the due date to ensure policy coverage</StyledText>
                        </>
                    ) : null}
                    {enrolPendingPolicyList
                        .filter(
                            (policy) =>
                                policy.enrolmentStatus?.toLowerCase() !== 'enroled' &&
                                !(pendingGMCPolicy().length && policy?.policyType === 'SUPER_TOP_UP')
                        )
                        .map((policy, index) => (
                            <StyledInsuranceContainer key={`EnrolPending-${index}`}>
                                <InsuranceCard
                                    policyId={policy.policyId}
                                    onClick={() => handleCardClick(policy)}
                                    insuranceCategory={policy?.policyType?.toUpperCase()}
                                    insuranceName={policy.policyName}
                                    policyImage={policy.policyImage}
                                    isEnrolPending={!(getDaysLeft(policy.enrolmentDueDate) === 'OVERDUE')}
                                    sumInsured={getSumInsured(policy.sumInsured, '')}
                                    dependentsInsured={policy.familyStruture}
                                    enrolmentDueDate={
                                        policy?.enrolmentDueDate
                                            ? insuranceCardDateFormat(policy.enrolmentDueDate)
                                            : 'N/A'
                                    }
                                    policyEndDate={
                                        policy?.policyEndDate
                                            ? insuranceCardDateFormat(policy?.policyEndDate?._seconds)
                                            : 'N/A'
                                    }
                                    annualPremium={policy?.annualPremium}
                                    daysLeft={getDaysLeft(policy.enrolmentDueDate)}
                                    dependents={policy.dependents}
                                    availableTopUp={getAvailableTopup(policy.availableTopUp)}
                                    topUpAdded={getSumInsured(policy.topUpAdded, '')}
                                    superTopupStatus={checkSuperTopUpAvailable}
                                    SI={policy.sumInsured}
                                    enrolmentStatus={policy.enrolmentStatus as string}
                                />
                            </StyledInsuranceContainer>
                        ))}
                    {checkShowSuperTopUpCard ? (
                        <StyledInsuranceContainer key={`EnrolPending-${4}`}>
                            <InsuranceCard
                                policyId=''
                                onClick={() => handleCardClick(superTopUpPolicy as IPolicy)}
                                insuranceCategory={superTopUpPolicy?.policyType?.toUpperCase() ?? ''}
                                insuranceName={superTopUpPolicy?.policyName ?? ''}
                                policyImage={superTopUpPolicy?.policyImage ?? ''}
                                isEnrolPending={!(getDaysLeft(superTopUpPolicy?.enrolmentDueDate ?? 0) === 'OVERDUE')}
                                sumInsured={getSumInsured(superTopUpPolicy?.sumInsured ?? 0, '')}
                                dependentsInsured={getEnrolledGMCPolicies[0]?.familyStruture ?? ''}
                                enrolmentDueDate={
                                    getEnrolledGMCPolicies[0]?.enrolmentDueDate
                                        ? insuranceCardDateFormat(getEnrolledGMCPolicies[0].enrolmentDueDate)
                                        : 'N/A'
                                }
                                policyEndDate={
                                    superTopUpPolicy?.policyEndDate
                                        ? insuranceCardDateFormat(superTopUpPolicy?.policyEndDate?._seconds)
                                        : 'N/A'
                                }
                                annualPremium={getEnrolledGMCPolicies[0]?.annualPremium}
                                daysLeft={getDaysLeft(getEnrolledGMCPolicies[0]?.enrolmentDueDate ?? 0)}
                                dependents={getEnrolledGMCPolicies[0]?.dependents ?? []}
                                availableTopUp={getAvailableTopup(getEnrolledGMCPolicies[0]?.availableTopUp ?? 0)}
                                topUpAdded={getSumInsured(getEnrolledGMCPolicies[0]?.topUpAdded ?? 0, '')}
                                superTopupStatus={checkSuperTopUpAvailable}
                            />
                        </StyledInsuranceContainer>
                    ) : null}
                    {enrolledDonePolicyList.length > 0 ? (
                        <StyledEnrolmentText $isDone={true}>
                            Enrolled Policies&nbsp;&nbsp;
                            <Label isActive={true}> DONE</Label>
                        </StyledEnrolmentText>
                    ) : null}
                    {enrolledDonePolicyList.map((policy: IPolicy, index: number) => (
                        <StyledInsuranceContainer key={`Enrolled-${index}`}>
                            <InsuranceCard
                                policyId={policy.policyId}
                                onClick={() => trackClick(getPolicyCardTrackObject(policy))}
                                insuranceCategory={policy?.policyType?.toUpperCase()}
                                insuranceName={policy.policyName}
                                isEnrolPending={false}
                                policyImage={policy.policyImage}
                                sumInsured={getSumInsured(policy.sumInsured, '')}
                                dependentsInsured={policy.familyStruture}
                                enrolmentDueDate={
                                    policy?.enrolmentDueDate ? insuranceCardDateFormat(policy.enrolmentDueDate) : 'N/A'
                                }
                                daysLeft={''}
                                policyEndDate={
                                    policy?.policyEndDate
                                        ? insuranceCardDateFormat(policy.policyEndDate._seconds)
                                        : 'N/A'
                                }
                                annualPremium={policy?.annualPremium}
                                dependents={policy.dependents}
                                availableTopUp={getAvailableTopup(policy.availableTopUp)}
                                enrolmentStatus={'ENROLED'}
                                topUpAdded={getSumInsured(policy.topUpAdded, '')}
                                superTopupStatus={checkSuperTopUpAvailable}
                                SI={policy.sumInsured}
                            />
                        </StyledInsuranceContainer>
                    ))}
                    <StyledNoPolicyText>
                        {showPolicyMsgDashboard(sortPolicyList.length)}
                    </StyledNoPolicyText>
                </>
            )}
        </StyledContainer>
    );
};

export default DashboardPage;
