import React from 'react';
import { replaceFamilyStructureText } from '../../../utils/common';
import {
    StyledContainer,
    StyledInsuranceDetailHeading,
    StyledInsuranceDetailContent,
    StyledInsuranceDetailContainer,
    StyledErrorText
} from './styles';
import { IInsuranceCardDetail } from './types';
import { isCheckoutDisabled } from '../../../utils/featureFlags';

const InsuranceCardDetail: React.FunctionComponent<IInsuranceCardDetail> = ({
    sumInsured,
    dependentsInsured,
    displayForEnrolment = false,
    availableTopUp,
    daysLeft,
    enrolmentDueDate,
    enrolmentStatus,
    topUpAdded,
    insuranceCategory,
    policyEndDate,
    annualPremium,
    policyId
}) => {
    const isSuperTopupAvailable =
        enrolmentStatus?.toLowerCase() === 'enroled' ? topUpAdded || 'N/A' : availableTopUp || 'N/A';
    return (
        <StyledContainer>
            <StyledInsuranceDetailContainer>
                <StyledInsuranceDetailHeading>SUM INSURED</StyledInsuranceDetailHeading>
                <StyledInsuranceDetailContent>
                    {insuranceCategory === 'SUPER_TOP_UP' && enrolmentStatus !== 'ENROLED' ? 'Upto ' : ''}
                    {'₹'}
                    {sumInsured.trim() ? sumInsured : 'N/A'}
                </StyledInsuranceDetailContent>
            </StyledInsuranceDetailContainer>
            {insuranceCategory === 'SUPER_TOP_UP' ? (
                <StyledInsuranceDetailContainer>
                    {/* TODO: Temporary handling hide Premium starting at Section. to be used later.
                    https://loop-health.atlassian.net/browse/MA-2577 */}
                    {/* <StyledInsuranceDetailHeading>
                        {enrolmentStatus?.toLowerCase() === 'enroled' ? 'ANNUAL PREMIUM PAID' : 'PREMIUMS STARTING AT'}
                    </StyledInsuranceDetailHeading>
                    <StyledInsuranceDetailContent>
                        {enrolmentStatus?.toLowerCase() === 'enroled'
                            ? annualPremium && annualPremium > 0
                                ? `₹` + annualPremium?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : null
                            : `₹` +
                                  getStartingPremiumCompany()
                                      ?.toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                                  ' per year' || 'N/A'}
                    </StyledInsuranceDetailContent> */}
                    <StyledInsuranceDetailHeading>
                        {enrolmentStatus?.toLowerCase() === 'enroled' ? 'ANNUAL PREMIUM PAID' : null}
                    </StyledInsuranceDetailHeading>
                    <StyledInsuranceDetailContent>
                        {enrolmentStatus?.toLowerCase() === 'enroled'
                            ? annualPremium && annualPremium > 0
                                ? `₹` + annualPremium?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                : null
                            : null}
                    </StyledInsuranceDetailContent>
                </StyledInsuranceDetailContainer>
            ) : isCheckoutDisabled(policyId) ? (
                <></>
            ) : (
                <StyledInsuranceDetailContainer>
                    <StyledInsuranceDetailHeading>
                        TOP-UP {enrolmentStatus?.toLowerCase() === 'enroled' ? 'ADDED' : 'AVAILABLE'}
                    </StyledInsuranceDetailHeading>
                    <StyledInsuranceDetailContent>{isSuperTopupAvailable}</StyledInsuranceDetailContent>
                </StyledInsuranceDetailContainer>
            )}
            {!displayForEnrolment && insuranceCategory !== 'SUPER_TOP_UP' ? (
                <StyledInsuranceDetailContainer>
                    <StyledInsuranceDetailHeading>COVERED MEMBERS</StyledInsuranceDetailHeading>
                    <StyledInsuranceDetailContent>
                        {dependentsInsured ? replaceFamilyStructureText(dependentsInsured) : 'N/A'}
                    </StyledInsuranceDetailContent>
                </StyledInsuranceDetailContainer>
            ) : null}

            {insuranceCategory === 'SUPER_TOP_UP' && enrolmentStatus === 'ENROLED' && (
                <StyledInsuranceDetailContainer>
                    <StyledInsuranceDetailHeading>VALID TILL</StyledInsuranceDetailHeading>
                    <StyledInsuranceDetailContent>{policyEndDate ? policyEndDate : 'N/A'}</StyledInsuranceDetailContent>
                </StyledInsuranceDetailContainer>
            )}
            {insuranceCategory !== 'SUPER_TOP_UP' && displayForEnrolment && (
                <StyledInsuranceDetailContainer>
                    <StyledInsuranceDetailHeading>ENROLMENT DUE DATE</StyledInsuranceDetailHeading>
                    <StyledInsuranceDetailContent>
                        {enrolmentDueDate?.trim() ? enrolmentDueDate : 'N/A'}
                        {daysLeft.length !== 0 && (
                            <StyledErrorText>
                                {!displayForEnrolment && <>&nbsp;&nbsp;</>}
                                {daysLeft.trim() ? (daysLeft !== 'OVERDUE' ? daysLeft : '') : 'N/A'}
                            </StyledErrorText>
                        )}
                    </StyledInsuranceDetailContent>
                </StyledInsuranceDetailContainer>
            )}
        </StyledContainer>
    );
};

export default InsuranceCardDetail;
