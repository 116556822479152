import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import {
    StyledContainer,
    StyledContent,
    StyledRow,
    StyledSummaryText,
    StyledMenuContainer,
    StyledContainerWrapper,
    StyledSummarySpan,
    StyledSummaryDaysSpan
} from './styles';
import { ReduxState } from '../../../../../redux';
import { updateSelectedTopUp } from '../../../../../redux/slices/EnrolmentSlice';
import {
    getSumInsured,
    capitalizeFirstLetter,
    getDaysLeft,
    insuranceCardDateFormat,
    checkIfTopUpAvailable,
    getSegmentPageName
} from '../../../../../utils/common';
import { EmployeeSummaryNotice, Stepper, SummaryFooter } from '../../../../atoms';
import SummaryCard from '../../../../containers/SummaryCard';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import useSegment from '../../../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../../../utils/constants/SegmentActionConstants';
import { IMemberTopUp } from '../../../../../redux/slices/EnrolmentSlice/types';
import { confirmationEvents } from '../../../../../utils/clevertapEvents';
import { setCurrentStepperStep } from '../../../../../redux/slices/ApplicationStateSlice';
import { LocationState } from '../../../../../topup-src/utils/types';
import { FreshChat } from '../../../../atoms/FreshChat';
import { checkTopUpAvailable, getEnrolledPolicyCompany } from '../../../../../utils/PolicyServices';

const SummaryPage: React.FunctionComponent = () => {
    const trackClick = useSegment('click');
    const trackPage = useSegment('page');
    const dispatch = useDispatch();
    const location = useLocation<LocationState>();
    const history = useHistory();
    const userData = useSelector((state: ReduxState) => state.user.userData);
    const selectedMemberTopUp = useSelector((state: ReduxState) => state.enrolment.selectedMemberTopUp);
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const updatePolicy = useSelector((state: ReduxState) => state.enrolment.updateEnrolmentStatus);
    const updateTopup = useSelector((state: ReduxState) => state.enrolment.updateTopUpOption);
    const checkIfSuperTopUpSelected = useSelector((state: ReduxState) => state.applicationState.isSuperTopupSelected);
    const topUpAmount: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const [formattedDependents, setFormattedDependents] = React.useState<string[]>([]);
    const [formattedTopup, setFormattedTopup] = React.useState<string[]>([]);
    const currentSelectedStep = useSelector((state: ReduxState) => state.applicationState.currentStepperStep);
    const topUpDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const userFeedback = useSelector((state: ReduxState) => state.applicationState.unSelectTopupFeedback);
    const policyList = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const getEnrolledGMCPolicies = getEnrolledPolicyCompany(policyList);
    const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
    const refPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location?.state?.previousUrl) as any];
    const checkSuperTopUp = checkTopUpAvailable();
    const superTopUp = checkIfTopUpAvailable(checkSuperTopUp, currentlySelectedPolicy);
    React.useEffect(() => {
        trackPage({
            name: currentPageAction.name,
            properties: {
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                page_title: currentPageAction.properties?.page_title,
                login_status: currentPageAction.properties?.login_status,
                referrer_page_title:
                    (currentPageAction.properties?.referrer_page_title as (refPage: string) => string)(
                        refPageAction?.name
                    ) || '',
                referrer_page_url:
                    (currentPageAction.properties?.referrer_page_url as (refUrl: string) => string)(
                        location?.state?.previousUrl
                    ) || '',
                enrolling_in: (currentPageAction.properties?.enrolling_in as (enrolledIN: string) => string)(
                    getEnrolledGMCPolicies.length
                        ? checkIfSuperTopUpSelected
                            ? 'topup'
                            : 'both'
                        : checkIfSuperTopUpSelected
                            ? 'both'
                            : 'GMC'
                ),
                sum_insured: (currentPageAction.properties?.sum_insured as (sumInsured: string) => string)(
                    topUpDetails?.sumInsured
                ),
                user_feedback: (currentPageAction.properties?.user_feedback as (feedback: string) => string)(
                    userFeedback
                )
            }
        });
    }, [currentPageAction]);
    React.useEffect(() => {
        if (currentlySelectedPolicy?.dependents) {
            const deps: string[] = [];
            for (let i = 0; i < currentlySelectedPolicy?.dependents?.length; i++) {
                const data: any = currentlySelectedPolicy?.dependents[i];
                const formattedData = {
                    firstName: capitalizeFirstLetter(data.firstName.toLowerCase()),
                    lastName: capitalizeFirstLetter(data.lastName.toLowerCase()),
                    gender: data.gender,
                    DOB: new Date(data.doB * 1000).toLocaleString().split(',')[0],
                    relationship: data.relationship
                };
                const { firstName, lastName, gender, DOB, relationship } = formattedData;
                deps.push(
                    `Name: ${firstName} ${lastName}, Relationship: ${relationship}, Gender: ${gender}, DOB: ${DOB}`
                );
            }
            setFormattedDependents(deps);
        }
        if (selectedMemberTopUp) {
            const topupData: string[] = [];
            for (let i = 0; i < selectedMemberTopUp.length; i++) {
                const data: IMemberTopUp = selectedMemberTopUp[i];
                const formattedData = {
                    topupName: data.topupName,
                    topupCoverage: data.sumInsured,
                    coveredMembers: data.coveredMembers,
                    premium: data.employeePremiumAmount,
                    employerPercent: 100 - data.employeeContribution,
                    annualContribution: data.annualPremium
                };
                const {
                    topupName,
                    topupCoverage,
                    coveredMembers,
                    premium,
                    employerPercent,
                    annualContribution
                } = formattedData;
                topupData.push(`Top-Up Name: ${topupName || '-'}, Top-Up Coverage: ${topupCoverage || '-'},
                Members Covered: ${coveredMembers || '-'}, Premium Amount: ${premium || '-'},
                % Paid by Employer: ${employerPercent || '-'}, Annual Contribution: ${annualContribution || '-'}`);
            }

            setFormattedTopup(topupData);
        }
    }, [currentlySelectedPolicy]);

    React.useEffect(() => {
        if (currentlySelectedPolicy && currentlySelectedPolicy.enrolmentStatus?.toLowerCase() === 'enroled') {
            trackClick(confirmationEvents(currentlySelectedPolicy, formattedDependents, formattedTopup));
            history.push(INTERNAL_ROUTES.confirm, { previousUrl: location.pathname });
        }
    }, [updatePolicy, updateTopup]);
    // handle the different cases and cleanup of currentlySelectedPolicy
    React.useEffect(() => {
        if (!currentlySelectedPolicy) {
            history.push(INTERNAL_ROUTES.dashboard, { previousUrl: location.pathname });
        }
    }, [currentlySelectedPolicy]);
    const handleBack = (e: React.MouseEvent) => {
        const buttonId = e.currentTarget.id;
        const segmentAction = SEGMENT_ACTIONS.CLICK.SUMMARY_GO_BACK;
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (buttonId: string) => string)(buttonId) || '',
                button_id: (segmentAction.properties?.button_id as (buttonId: string) => string)(buttonId) || '',
                page_title: segmentAction.properties?.page_title,
                login_status: segmentAction.properties?.login_status,
                sum_insured: (currentPageAction.properties?.sum_insured as (sumInsured: string) => string)(
                    topUpDetails?.sumInsured
                ),
                enrolling_in: (currentPageAction.properties?.enrolling_in as (enrolledIN: string) => string)(
                    getEnrolledGMCPolicies.length
                        ? checkIfSuperTopUpSelected
                            ? 'topup'
                            : 'both'
                        : checkIfSuperTopUpSelected
                            ? 'both'
                            : 'GMC'
                ),
                user_feedback: (currentPageAction.properties?.user_feedback as (feedback: string) => string)(
                    userFeedback
                )
            }
        });
        dispatch(setCurrentStepperStep({ currentStepperStep: currentSelectedStep - 1 }));
        if (superTopUp) {
            history.push(INTERNAL_ROUTES.topup, { previousUrl: location.pathname });
        } else {
            history.push(INTERNAL_ROUTES.enrolment, { previousUrl: location.pathname });
        }
    };
    const handleConfirm = () => {
        if (checkIfSuperTopUpSelected) {
            const segmentAction = SEGMENT_ACTIONS.CLICK.SUMMARY_CONFIRM_CHECKOUT;
            trackClick({
                name: segmentAction.name,
                properties: {
                    describe: segmentAction.properties?.describe || '',
                    login_status: segmentAction.properties?.login_status,
                    page_title: segmentAction.properties?.page_title,
                    page_url:
                        (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                    page_id:
                        (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                    enrolling_in: (currentPageAction.properties?.enrolling_in as (enrolledIN: string) => string)(
                        getEnrolledGMCPolicies.length
                            ? checkIfSuperTopUpSelected
                                ? 'topup'
                                : 'both'
                            : checkIfSuperTopUpSelected
                                ? 'both'
                                : 'GMC'
                    ),
                    sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                        topUpDetails?.sumInsured
                    ),
                    user_feedback: (currentPageAction.properties?.user_feedback as (feedback: string) => string)(
                        userFeedback
                    )
                }
            });
            dispatch(setCurrentStepperStep({ currentStepperStep: currentSelectedStep + 1 }));
            history.push(INTERNAL_ROUTES.checkout, { previousUrl: location.pathname });
        } else {
            dispatch(
                updateSelectedTopUp({
                    policyId: currentlySelectedPolicy?.policyId || '',
                    topUpDetails: selectedMemberTopUp,
                    userId: userData.data.userId
                })
            );
        }
    };
    const getEmployeePremium = () => {
        if (currentlySelectedPolicy?.annualPremium) {
            if (selectedMemberTopUp) {
                return (
                    currentlySelectedPolicy.annualPremium +
                    selectedMemberTopUp.map((slab) => slab.employeePremiumAmount).reduce((prev, curr) => prev + curr, 0)
                );
            } else {
                return currentlySelectedPolicy.annualPremium;
            }
        }
        let calAP = currentlySelectedPolicy?.annualPremium;
        if (checkIfSuperTopUpSelected && superTopUp) {
            return (calAP += topUpAmount?.annualPremium ?? 0);
        } else {
            return calAP;
        }
    };
    return (
        <StyledContainerWrapper>
            {checkIfSuperTopUpSelected ? <FreshChat /> : null}
            <StyledMenuContainer>
                <Stepper />
            </StyledMenuContainer>
            <StyledContainer>
                {checkIfSuperTopUpSelected && currentlySelectedPolicy?.enrolmentDueDate && (
                    <StyledRow>
                        {getDaysLeft(currentlySelectedPolicy?.enrolmentDueDate) === 'OVERDUE' ? null : (
                            <EmployeeSummaryNotice errorNotice={true}>
                                ENROLMENT DUE DATE IS{' '}
                                <StyledSummaryDaysSpan>
                                    {insuranceCardDateFormat(currentlySelectedPolicy?.enrolmentDueDate ?? 0)}
                                </StyledSummaryDaysSpan>
                                {superTopUp && (
                                    <>
                                        . You have{' '}
                                        <StyledSummarySpan>
                                            {getDaysLeft(currentlySelectedPolicy?.enrolmentDueDate ?? 0)}
                                        </StyledSummarySpan>{' '}
                                        to add a top-up cover for you and your family.
                                    </>
                                )}
                            </EmployeeSummaryNotice>
                        )}

                        <StyledSummaryText>Enrolment Summary</StyledSummaryText>
                    </StyledRow>
                )}

                {currentlySelectedPolicy && (
                    <StyledContent>
                        <SummaryCard
                            insuranceCategory={currentlySelectedPolicy.policyType}
                            insuranceName={currentlySelectedPolicy.policyName}
                            dependents={currentlySelectedPolicy.dependents}
                            policyImage={currentlySelectedPolicy.policyImage}
                            coveredMembers={currentlySelectedPolicy.familyStruture}
                            sumInsured={getSumInsured(currentlySelectedPolicy.sumInsured, '')}
                            familyStructure={currentlySelectedPolicy.familyStruture}
                            selectedMemberTopUp={selectedMemberTopUp}
                        />
                    </StyledContent>
                )}
            </StyledContainer>
            <SummaryFooter
                loading={updatePolicy.loading || updateTopup.loading}
                error={updatePolicy.error || updateTopup.error}
                onSaveDetailsClick={handleConfirm}
                onGoBackClick={handleBack}
                memberTopUpPremium={getEmployeePremium()}
                policyId={currentlySelectedPolicy?.policyId || ''}
            />
        </StyledContainerWrapper>
    );
};

export default SummaryPage;
