import { Dispatch, SetStateAction } from 'react';
import { setCurrentStepperStep, setUnSelectTopupFeedback } from '../../../redux/slices/ApplicationStateSlice';
import { ISegmentAct, SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { IStepperMeta } from '../../atoms/Stepper/types';
import { getTrackClickConfig } from '../../../utils/SegmentEvents';
import { History } from 'history';
import { IPolicy } from '../../../redux/slices/PolicyListSlice/types';
import { IMemberTopUp } from '../../../redux/slices/EnrolmentSlice/types';
import { getSumInsured } from '../../../utils/common';
import { STEPPER_META, STEPPER_META_NO_TOP_UP, STEPPER_META_SUPER_TOPUP } from '../../../utils/constants';

export const getStepperMetaDataUtil = (
    superTopUpAvailable: boolean,
    currentlySelectedPolicy: IPolicy | null,
    compTopUp: boolean
): IStepperMeta[] => superTopUpAvailable &&
(currentlySelectedPolicy?.policyType === 'GMC' || currentlySelectedPolicy?.policyType === 'SUPER_TOP_UP')
    ? STEPPER_META_SUPER_TOPUP
    : compTopUp
        ? STEPPER_META
        : STEPPER_META_NO_TOP_UP;

export const handleNavigationUtil = (
    currentSelectedStep: number,
    currentPageAction: ISegmentAct,
    superTopUpAvailable: boolean,
    topUpAmount: { sumInsured: string; },
    loading: boolean,
    isEnrolMemberValid: boolean,
    isNomineeEnabledForCompany: boolean,
    nomineeConsumedPercentage: number,
    setErrorMsg: Dispatch<SetStateAction<string>>,
    setNomineeModalErrorMsg: Dispatch<SetStateAction<{ title: string, description: string }>>,
    toggleNomineeValidationModalVisible: Dispatch<SetStateAction<boolean>>,
    dispatch: Dispatch<any>,
    trackClick: ({ name, properties }: {
        name: string;
        properties?: Record<string, unknown> | undefined;
    }) => void,
    showSummary: (param: boolean) => void,
    stepperMetaData: IStepperMeta[],
    history: unknown[] | History<unknown>
): void => {
    const pathName = location.pathname;
    const trackClickConfiguration = getTrackClickConfig(
        currentSelectedStep,
        currentPageAction,
        superTopUpAvailable,
        topUpAmount,
        pathName
    );
    if (!loading) {
        if (currentSelectedStep === 1 && !isEnrolMemberValid) {
            setErrorMsg('Please check your member data');
            return;
        }
        if (currentSelectedStep === 1 && isNomineeEnabledForCompany && nomineeConsumedPercentage !== 100) {
            setNomineeModalErrorMsg({
                title: `${100 - nomineeConsumedPercentage}% of your sum insured is yet to be assigned 
                to your nominees`,
                description: `Add another nominee or change the percentage assigned to previously added 
                nominees in order to proceed`
            });
            toggleNomineeValidationModalVisible(true);
            return;
        }
        setErrorMsg('');
        if (superTopUpAvailable && currentSelectedStep === 2) {
            dispatch(setUnSelectTopupFeedback({ unSelectTopupFeedback: 'SELECTED_TOP_UP' }));
            trackClick(trackClickConfiguration);
            showSummary(true);
            return;
        }
        trackClick(trackClickConfiguration);
        dispatch(setCurrentStepperStep({ currentStepperStep: currentSelectedStep + 1 }));
        history.push(stepperMetaData[currentSelectedStep].stepUrl ?? '', { previousUrl: location.pathname });
    }
};

export const handleRemoveTopUpBtnUtil = (
    trackClick: ({ name, properties }: {
        name: string;
        properties?: Record<string, unknown> | undefined;
    }) => void,
    currentPageAction: ISegmentAct,
    topUpAmount: { sumInsured: any; },
    setShowCancelModalIsVisible: Dispatch<SetStateAction<boolean>>
): void => {
    const segmentAction = SEGMENT_ACTIONS.CLICK.PROCEED_WITHOUT_TOPUP_MODAL;
    trackClick({
        name: segmentAction.name,
        properties: {
            describe: segmentAction.properties?.describe || '',
            page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
            page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
            sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                topUpAmount?.sumInsured || 0
            ),
            page_title: segmentAction.properties?.page_title,
            login_status: segmentAction.properties?.login_status
        }
    });
    setShowCancelModalIsVisible(true);
};

export const handleShowBreakdownUtil = (
    show: boolean,
    trackClick: ({ name, properties }: {
        name: string;
        properties?: Record<string, unknown> | undefined;
    }) => void,
    currentPageAction: ISegmentAct,
    topUpAmount: { sumInsured: any; },
    setShowBreakdown: Dispatch<SetStateAction<boolean>>
): void => {
    const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_SHOW_BREAKDOWN;
    trackClick({
        name: segmentAction.name,
        properties: {
            describe: segmentAction.properties?.describe || '',
            page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
            page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
            sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                topUpAmount?.sumInsured || ''
            ),
            page_title: segmentAction.properties?.page_title,
            login_status: segmentAction.properties?.login_status
        }
    });
    setShowBreakdown(show);
};

export const getSelfContributionUtil = (
    currentlySelectedPolicy: IPolicy | null,
    memberTopUp: IMemberTopUp[] | null
): number => {
    if (currentlySelectedPolicy?.annualPremium) {
        if (memberTopUp) {
            return (
                currentlySelectedPolicy.annualPremium +
                memberTopUp.map((slab) => slab.employeePremiumAmount).reduce((prev, curr) => prev + curr, 0)
            );
        } else {
            return currentlySelectedPolicy.annualPremium;
        }
    }
    if (memberTopUp) {
        return memberTopUp.map((slab) => slab.employeePremiumAmount).reduce((prev, curr) => prev + curr, 0);
    }
    return 0;
};

export const getTotalCoverageUtil = (
    memberTopUp: IMemberTopUp[] | null,
    currentlySelectedPolicy: IPolicy | null,
    superTopUpAvailable: boolean,
    topUpAmount: { sumInsured: string; }
): string | undefined => {
    if (!memberTopUp?.length && currentlySelectedPolicy) {
        let sumInsured = currentlySelectedPolicy?.sumInsured;
        if (superTopUpAvailable) {
            sumInsured += topUpAmount?.sumInsured;
        }
        return getSumInsured(sumInsured, '');
    } else if (memberTopUp && currentlySelectedPolicy) {
        let totalCoverage = getSumInsured(currentlySelectedPolicy?.sumInsured, '').trim();
        memberTopUp.map((item) => {
            totalCoverage = totalCoverage + ' + ' + getSumInsured(item.sumInsured, '');
            return totalCoverage;
        });
        return totalCoverage;
    }
};
