import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    StyledContainer,
    StyledContentContainer,
    StyledMenuContainer,
    NavigatorContainer,
    StyledDivContainer,
    StyledTitleText
} from './styles';
import { ReduxState } from '../../../../../redux';
import {
    fetchPolicyDetails,
    fetchTopUpOptions,
    resetUpdateTopUpOption,
    setSelectedMemberTopUp
} from '../../../../../redux/slices/EnrolmentSlice';
import { fetchNomineeConfig } from '../../../../../redux/slices/StepperSlice';

import { insuranceCardDateFormat, getAvailableTopup, getDaysLeft, getSumInsured } from '../../../../../utils/common';
import { ErrorContainer, Loader, Stepper } from '../../../../atoms';
import { EnrolMembers, EnrolmentCard, EnrolmentNavigator, TopUpMembers } from '../../../../containers';
import { INTERNAL_ROUTES } from '../../../../../utils/constants';
import { PARENTAL_POLICY } from '../../../../../utils/constants';
import { setCurrentStepperStep } from '../../../../../redux/slices/ApplicationStateSlice';
import { setShowCallbackToolTip } from '../../../../../topup-src/redux/slices/ApplicationStateSlice';
import { LocationState } from '../../../../../topup-src/utils/types';
import { useLocation } from 'react-router-dom';
const EnrolmentPage: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const location = useLocation<LocationState>();
    const history = useHistory();
    const selectedMemberTopUp = useSelector((state: ReduxState) => state.enrolment.selectedMemberTopUp);
    const companyId = useSelector((state: ReduxState) => state.user?.userData?.data?.employer || '');
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const topUpOptions = useSelector((state: ReduxState) => state.enrolment.topUpOptions);
    const updateTopUpOptions = useSelector((state: ReduxState) => state.enrolment.updateTopUpOption);
    const currentSelectedStep = useSelector((state: ReduxState) => state.applicationState.currentStepperStep);
    const [nomineeConsumedPercentage, setNomineeConsumedPercentage] = React.useState(0);
    const topupAvailable = !topUpOptions.loading ? (topUpOptions.data?.length ? true : false) : true;
    React.useEffect(() => {
        dispatch(setCurrentStepperStep({ currentStepperStep: 1 }));
        dispatch(setShowCallbackToolTip({ showCallbackToolTip: false }));
    }, []);

    // handle the different cases and cleanup of currentlySelectedPolicy
    React.useEffect(() => {
        if (!currentlySelectedPolicy) {
            history.push(INTERNAL_ROUTES.dashboard, { previousUrl: location.pathname });
            return;
        }

        if (updateTopUpOptions) {
            dispatch(resetUpdateTopUpOption());
        }

        if (currentlySelectedPolicy && currentlySelectedPolicy.policyId) {
            if (companyId) {
                dispatch(fetchNomineeConfig({ companyId, policyId: currentlySelectedPolicy.policyId }));
            }
            // fetch Company Provided topup options parallelly
            dispatch(
                fetchTopUpOptions({
                    policyId: currentlySelectedPolicy.policyId
                })
            );
            // add dispatch here
            dispatch(fetchPolicyDetails({ policyId: currentlySelectedPolicy.policyId }));
        }
    }, [currentlySelectedPolicy]);

    const showSummary = () => {
        history.push(INTERNAL_ROUTES.summary, { previousUrl: location.pathname });
    };

    const isEnrolMemberValidCheck = (): boolean => {
        if (currentlySelectedPolicy) {
            if (PARENTAL_POLICY.includes(currentlySelectedPolicy?.familyStruture)) {
                return !!(
                    currentlySelectedPolicy &&
                    currentlySelectedPolicy?.dependents.filter(
                        (dep: any) =>
                            dep?.relationship?.toLowerCase() === 'parent' ||
                            dep?.relationship?.toLowerCase() === 'parent-in-law'
                    ).length > 0
                );
            } else {
                return !!(
                    currentlySelectedPolicy &&
                    currentlySelectedPolicy?.dependents.filter(
                        (dep: any) => dep?.relationship?.toLowerCase() === 'self'
                    ).length > 0
                );
            }
        }
        return false;
    };
    return (
        <StyledContainer>
            <StyledMenuContainer>
                <Stepper />
            </StyledMenuContainer>
            <StyledContentContainer>
                {currentlySelectedPolicy && (
                    <>
                        {currentSelectedStep === 1 && (
                            <EnrolmentCard
                                policyId={currentlySelectedPolicy.policyId}
                                policyImage={currentlySelectedPolicy.policyImage}
                                insuranceName={currentlySelectedPolicy.policyName}
                                insuranceCategory={currentlySelectedPolicy.policyType}
                                sumInsured={getSumInsured(currentlySelectedPolicy.sumInsured, '')}
                                dependentsInsured={currentlySelectedPolicy.familyStruture}
                                enrolmentDueDate={
                                    currentlySelectedPolicy?.enrolmentDueDate
                                        ? insuranceCardDateFormat(currentlySelectedPolicy.enrolmentDueDate)
                                        : 'N/A'
                                }
                                daysLeft={getDaysLeft(currentlySelectedPolicy.enrolmentDueDate)}
                                availableTopUp={getAvailableTopup(currentlySelectedPolicy.availableTopUp)}
                                topUpAdded={getSumInsured(currentlySelectedPolicy.topUpAdded, '')}
                            >
                                <EnrolMembers
                                    dependents={currentlySelectedPolicy.dependents}
                                    familyStructure={currentlySelectedPolicy.familyStruture}
                                    nomineeConsumedPercentage={nomineeConsumedPercentage}
                                    setNomineeConsumedPercentage={setNomineeConsumedPercentage}
                                />
                            </EnrolmentCard>
                        )}
                        {topupAvailable && currentSelectedStep === 2 && (
                            <>
                                <EnrolmentCard
                                    policyId={currentlySelectedPolicy.policyId}
                                    policyImage={currentlySelectedPolicy.policyImage}
                                    insuranceName={currentlySelectedPolicy.policyName}
                                    insuranceCategory={currentlySelectedPolicy.policyType}
                                    sumInsured={getSumInsured(currentlySelectedPolicy.sumInsured, '')}
                                    dependentsInsured={currentlySelectedPolicy.familyStruture}
                                    enrolmentDueDate={
                                        currentlySelectedPolicy?.enrolmentDueDate
                                            ? insuranceCardDateFormat(currentlySelectedPolicy.enrolmentDueDate)
                                            : 'N/A'
                                    }
                                    daysLeft={getDaysLeft(currentlySelectedPolicy.enrolmentDueDate)}
                                    availableTopUp={getAvailableTopup(currentlySelectedPolicy.availableTopUp)}
                                    topUpAdded={getSumInsured(currentlySelectedPolicy.topUpAdded, '')}
                                >
                                    {topUpOptions.loading && <Loader />}
                                    {topUpOptions.error && <ErrorContainer message={topUpOptions.error.message} />}
                                    {!!topUpOptions.data?.length ? (
                                        <TopUpMembers
                                            selectedOption={selectedMemberTopUp}
                                            options={topUpOptions.data}
                                            setTopUp={(val) =>
                                                dispatch(
                                                    setSelectedMemberTopUp({
                                                        selectedMemberTopUp: val
                                                    })
                                                )
                                            }
                                        />
                                    ) : (
                                        <StyledDivContainer>
                                            <StyledTitleText>No TopUps Available</StyledTitleText>
                                        </StyledDivContainer>
                                    )}
                                </EnrolmentCard>
                            </>
                        )}
                    </>
                )}
            </StyledContentContainer>
            <NavigatorContainer>
                <EnrolmentNavigator
                    nomineeConsumedPercentage={nomineeConsumedPercentage}
                    loading={updateTopUpOptions.loading}
                    isEnrolMemberValid={isEnrolMemberValidCheck()}
                    showSummary={showSummary}
                    memberTopUp={selectedMemberTopUp}
                    currentStep={currentSelectedStep}
                />
            </NavigatorContainer>
        </StyledContainer>
    );
};

export default EnrolmentPage;
