import React from 'react';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import {
    StyledContainer,
    StyledAmount,
    AnnualPremiumContainer,
    StyledContainerHeader,
    StyledTopUpWrapper,
    StyledTopUpTitleText,
    StyledTopUpCoversText,
    StyledPremiumWrapper,
    StyledVerticalDivider,
    StyledPremiumTitleText,
    StyledPremiumText,
    StyledDiscountContainer,
    StyledDiscountText,
    StyledRemoveTag,
    StyledTopupAmountText,
    StyledTopupButtonWrapper,
    StyledRemoveTagWrapper,
    StyledDeleteImage,
    StyledMobileOnly
} from './styles';
import { ITopUpCard } from './types';
import { VerticalDividerImage, SuccessNew, DiscountBG, DeleteIcon } from '../../../assets/img';
import { Button } from '../../atoms';
import { getSumInsured, getPremiumAmount, replaceFamilyStructureText } from '../../../utils/common';
import { ITopUpOptionData } from '../../../redux/slices/EnrolmentSlice/types';

const TopUpCard: React.FunctionComponent<ITopUpCard> = ({ data, addTopUpPolicy, deleteTopUpPolicy }) => {
    const familyStructure =
        useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy?.familyStruture) || '';

    const [selectedSlabId, setSelectedSlabId] = React.useState(data?.topUpPolicySlabs?.[0]?.topUpSlabId);
    const [selectedSlab, setSelectedSlab] = React.useState<ITopUpOptionData>();
    const [isSelected, setIsSelected] = React.useState(false);
    const [premiumAmount, setPremiumAmount] = React.useState(0);
    React.useEffect(() => {
        setSelectedSlab(data?.topUpPolicySlabs?.filter((topUp) => topUp?.topUpSlabId === selectedSlabId)?.[0]);
    }, [selectedSlabId]);

    React.useEffect(() => {
        if (selectedSlab) {
            setPremiumAmount(getPremiumAmount(selectedSlab?.annualPremium, selectedSlab?.employeeContribution));
        }
    }, [selectedSlab]);

    const onClickSlab = (id: string) => {
        if (!isSelected) {
            setSelectedSlabId(id);
        }
    };
    const onClickPolicy = () => {
        setIsSelected(true);
        // save topup policy here
        addTopUpPolicy(
            selectedSlabId,
            data.policyId,
            premiumAmount,
            data.topUpName,
            selectedSlab?.familyDefinition?.value || ''
        );
    };
    const onRemove = () => {
        setIsSelected(false);
        // delete topup policy here
        deleteTopUpPolicy(data.policyId);
    };
    return (
        <StyledContainer $isActive={isSelected}>
            <StyledContainerHeader>
                <div>
                    <StyledTopUpTitleText>{data.topUpName ? data.topUpName : 'Top-Up'}</StyledTopUpTitleText>
                    <StyledTopUpCoversText>
                        {`Covers ${selectedSlab?.familyDefinition?.value ??
                            replaceFamilyStructureText(familyStructure || '')}`}
                    </StyledTopUpCoversText>
                </div>
                <StyledTopupButtonWrapper>
                    <Button
                        type={isSelected ? 'OAuth' : 'secondary'}
                        onClick={onClickPolicy}
                        isActive={isSelected}
                        iconSrc={isSelected ? SuccessNew : ''}
                        isTopUpBtn={true}
                    >
                        {isSelected ? 'Top-Up Added' : 'Add Top-Up'}
                    </Button>
                    {isSelected && (
                        <StyledRemoveTagWrapper>
                            <StyledRemoveTag onClick={onRemove}>
                                <StyledDeleteImage src={DeleteIcon} alt="Remove TopUp" />
                                REMOVE
                            </StyledRemoveTag>
                        </StyledRemoveTagWrapper>
                    )}
                </StyledTopupButtonWrapper>
            </StyledContainerHeader>

            <StyledPremiumWrapper>
                <div>
                    <StyledTopupAmountText>Select Top-Up Amount</StyledTopupAmountText>
                    <div>
                        {data?.topUpPolicySlabs?.map((slab) => {
                            return (
                                <Button
                                    key={slab.topUpSlabId}
                                    type="pills"
                                    onClick={() => onClickSlab(slab.topUpSlabId)}
                                    isActive={selectedSlabId === slab.topUpSlabId ? true : false}
                                >
                                    {getSumInsured(slab.sumInsured, '')}
                                </Button>
                            );
                        })}
                    </div>
                </div>
                <StyledVerticalDivider src={VerticalDividerImage} />
                <StyledTopUpWrapper>
                    <StyledPremiumTitleText>Your Annual Premium</StyledPremiumTitleText>
                    <AnnualPremiumContainer>
                        <StyledAmount>₹{(premiumAmount ?? 0).toFixed(2)}</StyledAmount>
                        <StyledDiscountContainer $src={DiscountBG}>
                            <StyledDiscountText>
                                {selectedSlab ? 100 - selectedSlab.employeeContribution : 0}% Paid by your employer
                            </StyledDiscountText>
                        </StyledDiscountContainer>
                    </AnnualPremiumContainer>
                    <StyledPremiumText>
                        ₹{selectedSlab ? selectedSlab.annualPremium : 'N/A'} (Total Annual Premium)
                    </StyledPremiumText>
                </StyledTopUpWrapper>
            </StyledPremiumWrapper>
            <StyledMobileOnly>
                <Button
                    type={isSelected ? 'OAuth' : 'secondary'}
                    onClick={onClickPolicy}
                    isActive={isSelected}
                    iconSrc={isSelected ? SuccessNew : ''}
                    isTopUpBtn={true}
                    fullWidth={true}
                >
                    {isSelected ? 'Top-Up Added' : 'Add Top-Up'}
                </Button>
                {isSelected && (
                    <StyledRemoveTagWrapper>
                        <StyledRemoveTag onClick={onRemove}>
                            <StyledDeleteImage src={DeleteIcon} alt="Remove TopUp" />
                            REMOVE
                        </StyledRemoveTag>
                    </StyledRemoveTagWrapper>
                )}
            </StyledMobileOnly>
        </StyledContainer>
    );
};

export default TopUpCard;
