import { IDependent } from '../../../redux/slices/PolicyListSlice/types';
import { getAgeFromDOB } from '../../../utils/common';
import { COMPANY_ID } from '../../../utils/constants';
import { IInsurancePropsType } from './types';

export const showTaxStripUtil = (
    insuranceProps: IInsurancePropsType,
    SI: string | undefined,
    enrolmentStatus: string | undefined,
    superTopupStatus: string | undefined,
    companyId: string,
    dependents: IDependent[]
): boolean => {
    if (
        insuranceProps.insuranceCategory == 'GMC' &&
        Number(SI) >= 200000 &&
        enrolmentStatus !== 'ENROLED' &&
        (superTopupStatus === 'NOT_SELECTED' || superTopupStatus === 'PENDING')
    ) {
        // TODO: temp check for specific company
        if (
            typeof companyId != 'undefined' &&
            COMPANY_ID.includes(companyId) &&
            insuranceProps.dependentsInsured.toLowerCase().includes('self')
        ) {
            return false;
        }
        const filteredUserDOB = dependents.find((user: { doB: number }) => {
            return getAgeFromDOB(user.doB) > 90;
        });
        if (typeof filteredUserDOB != 'undefined') {
            return false;
        }
        return true;
    } else if (insuranceProps.insuranceCategory == 'SUPER_TOP_UP' && enrolmentStatus !== 'ENROLED') {
        return true;
    }
    return false;
};
