import React from 'react';
import {
    StyledContainer,
    StyledInsuranceDetailContainer,
    StyledInsuranceDetailContent,
    StyledInsuranceDetailHeading
} from './styles';
import { IConfirmationCardDetails } from './types';
import { getSumInsured, replaceFamilyStructureText } from '../../../utils/common';

const ConfirmationCardDetails: React.FunctionComponent<IConfirmationCardDetails> = ({
    coveredMembers,
    premiumToPay,
    sumInsured,
    topUpAmount,
    policyCategory,
    annualPremium,
    policyEndDate
}) => {
    return (
        <StyledContainer>
            <StyledInsuranceDetailContainer>
                <StyledInsuranceDetailHeading>Covered Members</StyledInsuranceDetailHeading>
                <StyledInsuranceDetailContent>
                    {replaceFamilyStructureText(coveredMembers)}
                </StyledInsuranceDetailContent>
            </StyledInsuranceDetailContainer>
            <StyledInsuranceDetailContainer>
                <StyledInsuranceDetailHeading>Sum Insured</StyledInsuranceDetailHeading>
                <StyledInsuranceDetailContent>₹{getSumInsured(sumInsured, '')}</StyledInsuranceDetailContent>
            </StyledInsuranceDetailContainer>
            {policyCategory !== 'SUPER_TOP_UP' && <StyledInsuranceDetailContainer>
                <StyledInsuranceDetailHeading>Top-up Amount</StyledInsuranceDetailHeading>
                <StyledInsuranceDetailContent>
                    ₹{topUpAmount ? getSumInsured(topUpAmount, '') : 'N/A'}
                </StyledInsuranceDetailContent>
            </StyledInsuranceDetailContainer> }
            {policyCategory === 'SUPER_TOP_UP' ? (<StyledInsuranceDetailContainer>
                <StyledInsuranceDetailHeading>Premium paid</StyledInsuranceDetailHeading>
                <StyledInsuranceDetailContent>₹ {annualPremium ? annualPremium
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 'N/A'}</StyledInsuranceDetailContent>
            </StyledInsuranceDetailContainer>)
                :
                <StyledInsuranceDetailContainer>
                    <StyledInsuranceDetailHeading>Premium to be paid</StyledInsuranceDetailHeading>
                    <StyledInsuranceDetailContent>₹{premiumToPay ? premiumToPay : 'N/A'}</StyledInsuranceDetailContent>
                </StyledInsuranceDetailContainer>}
            {policyCategory === 'SUPER_TOP_UP' && (<StyledInsuranceDetailContainer>
                <StyledInsuranceDetailHeading>Valid Till</StyledInsuranceDetailHeading>
                <StyledInsuranceDetailContent>{policyEndDate}</StyledInsuranceDetailContent>
            </StyledInsuranceDetailContainer>)}
        </StyledContainer>
    );
};

export default ConfirmationCardDetails;
