import { IStepperMeta } from '../../components/atoms/Stepper/types';
import { Stepper1, Stepper2, SummaryImg } from '../../assets/img';
import { INTERNAL_ROUTES } from './RouteConstants';

export const STEPPER_META: IStepperMeta[] = [
    {
        id: 1,
        name: 'Enrol members',
        btnCTA: 'Proceed',
        stepUrl: INTERNAL_ROUTES.enrolment,
        stepNo: 1,
        image: Stepper1,
        description: `It’s important to enrol yourself by adding your details.
                        This will qualify you for future claims and ensure
                        protection. We recommend doing this before the window
                        closes.`
    },
    {
        id: 2,
        name: 'Member Top-Up',
        stepUrl: INTERNAL_ROUTES.enrolment,
        btnCTA: 'Proceed',
        image: Stepper2,
        stepNo: 2,
        description: `Top-up plans are an effective way to increase your
                        health insurance cover. It shields you from additional
                        expenses after your sum insured is exhausted. We
                        recommend choosing the option best suited to your health
                        needs.`
    },
    {
        id: 3,
        name: 'Summary',
        stepUrl: INTERNAL_ROUTES.summary,
        btnCTA: 'Proceed to Summary',
        stepNo: 3,
        description: `Please make sure the member details entered here are correct.
         Once you confirm the details, you won’t be able to edit these later.`,
        image: SummaryImg
    },
    {
        id: 4,
        name: 'Done',
        stepNo: 4,
        description: ``,
        image: ''
    }
];

export const STEPPER_META_NO_TOP_UP: IStepperMeta[] = [
    {
        id: 1,
        name: 'Enrol members',
        btnCTA: 'Proceed',
        stepUrl: INTERNAL_ROUTES.enrolment,
        stepNo: 1,
        image: Stepper1,
        description: `It’s important to enrol yourself by adding your details.
                        This will qualify you for future claims and ensure
                        protection. We recommend doing this before the window
                        closes.`
    },
    {
        id: 2,
        name: 'Summary',
        stepUrl: INTERNAL_ROUTES.summary,
        btnCTA: 'Proceed to Summary',
        stepNo: 2,
        description: `Please make sure the member details entered here are correct.
         Once you confirm the details, you won’t be able to edit these later.`,
        image: SummaryImg
    },
    {
        id: 3,
        name: 'Done',
        stepNo: 3,
        description: ``,
        image: ''
    }
];

export const STEPPER_META_SUPER_TOPUP: IStepperMeta[] = [
    {
        id: 1,
        name: 'Enrol members',
        stepUrl: INTERNAL_ROUTES.enrolment,
        btnCTA: 'Select Super Top-up',
        stepNo: 1,
        image: Stepper1,
        description: `It’s important to enrol yourself by adding your details.
                        This will qualify you for future claims and ensure
                        protection. We recommend doing this before the window
                        closes.`
    },
    {
        id: 2,
        name: 'Family Top-up',
        stepUrl: INTERNAL_ROUTES.topup,
        btnCTA: 'Add Super Top-Up',
        image: Stepper2,
        stepNo: 2,
        description: `Increase your health insurance cover with Loop. 
                        With the rising medical costs we recommend each
                        family member is covered for at least ₹3 Lakhs.  `
    },
    {
        id: 3,
        name: 'Summary',
        stepUrl: INTERNAL_ROUTES.summary,
        btnCTA: 'Proceed to Summary',
        stepNo: 3,
        description: `You are one step away from securing your family. Check your details to complete the final step.`,
        image: SummaryImg
    },
    {
        id: 4,
        name: 'Done',
        stepNo: 4,
        description: ``,
        image: ''
    }
];
