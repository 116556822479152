import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DowntimePage } from './components/pages';
import MainRouter from './components/pages/router';
import { ReduxState } from './redux';
import { fetchTopUpAvailabiltyStatus } from './redux/slices/EnrolmentSlice';
import { getCompanyDetails } from './redux/slices/PolicyListSlice/thunks';
import { getAgeFromDOB } from './utils/common';
import useSegment, { SegmentProvider } from './utils/hooks/useSegment';
import { sendUserPropertiesToSegment } from './utils/SegmentEvents';
import { StyledContainer } from './styles';
import { LogoutAfterInactivity } from '@loophealth/loop-ui-web-library';
import { useHistory } from 'react-router-dom';
import { logoutUser } from './components/pages/LoginContainer/redux/slices/UserSlice';
import { MAIN_ROUTES } from './utils/constants';
import moment from 'moment-timezone';
import { FirebaseAuth } from './adapters/provider';
import WithThemeProvider from './theme/WithThemeProvider';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
const isMaintenanceMode = false;

const App: React.FunctionComponent = () => {
    const companyId = useSelector((state: ReduxState) => state.user?.userData?.data?.employer || '');
    const companyDetails = useSelector((state: ReduxState) => state.policyList?.companyDetails?.data);
    const policy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const loginMethod = useSelector((state: ReduxState) => state.user?.userData?.data?.loginMethod);
    const user = useSelector((state: ReduxState) => state.user?.userData?.data || '');
    const checkSuperTopUpAvailable = useSelector((state: ReduxState) => state.enrolment?.superTopUpPolicy);
    const dispatch = useDispatch();
    const history = useHistory();
    const checkIfLoggedIn = () => {
        return !!FirebaseAuth.currentUser;
    };
    const handleOnLogout = () => {
        if (checkIfLoggedIn()) {
            dispatch(logoutUser());
            history.replace(MAIN_ROUTES.login);
        }
    };
    const trackUserProperties = useSegment('identify');
    const userProperties = {
        user_id: user.userId || '',
        employer_id: companyId,
        email: user.email,
        phone: user.mobile,
        employer_name: companyDetails?.companyName || '',
        age: getAgeFromDOB(user.dob?._seconds || 0).toString(),
        gender: user.gender || '',
        policy_id: policy?.policyId || '',
        policy_type: policy?.policyType || '',
        policy_status: policy?.enrolmentStatus || '',
        login_application: process.env.REACT_APP_NAME,
        timezone: moment.tz.guess() ?? '',
        screen: {
            height: Math.max(document?.documentElement?.clientHeight || 0, window?.innerHeight || 0),
            width: Math.max(document?.documentElement?.clientWidth || 0, window?.innerWidth || 0)
        },
        user_type: user?.patientType || '',
        login_method_type: user?.loginMethod,
        login_method: loginMethod
    };
    React.useEffect(() => {
        if (user.userId) {
            const userId = user.userId;
            window.plotline('init', process.env.REACT_APP_PLOTLINE_KEY ?? '', userId);
            dispatch(getCompanyDetails({ companyId }));
            dispatch(fetchTopUpAvailabiltyStatus({ userId }));
        }
    }, [user?.userId]);
    React.useEffect(() => {
        sendUserPropertiesToSegment(trackUserProperties, userProperties, checkSuperTopUpAvailable);
    }, [checkSuperTopUpAvailable.loading]);
    return isMaintenanceMode ? (
        <DowntimePage />
    ) : (
        <WithThemeProvider>
            <SegmentProvider
                value={{
                    companyId: companyId,
                    userId: user.userId || '',
                    login_method_type: user?.loginMethod,
                    login_method: loginMethod,
                    commonMeta: userProperties
                }}
            >
                <StyledContainer>
                    <LogoutAfterInactivity logoutAction={handleOnLogout}>
                        <MainRouter />
                    </LogoutAfterInactivity>
                </StyledContainer>
            </SegmentProvider>
        </WithThemeProvider>
    );
};

export default App;
