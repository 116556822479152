import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchEnrolmentTopUpOptions,
    saveEnrolmentTopUpOptions,
    updateUserPolicyEnrolmentStatus,
    fetchPolicyPreValidations,
    fetchSIAndAnnualPremium,
    fetchSuperTopupAvailableDetails,
    enrolUserToPolicyFamily,
    addExistingAPI,
    fetchSelfUserPolicy,
    addMemberAPI,
    editMembersAPI,
    offboardMemberAPI
} from '../../../adapters';
import { FirebaseTimestamp } from '../../../adapters/provider';
import { DynamicField } from '../../../components/atoms/DynamicField/types';
import { IAdapterData } from '../../types';
import { setShowAddModal, setShowDeleteModal, setShowEditModal } from '../ApplicationStateSlice';
import { fetchUserPolicyList, updatePolicyList } from '../PolicyListSlice';
import { IDependent, IDependentBase, IPolicy } from '../PolicyListSlice/types';
import { ICRUDResponse, IMemberTopUp, ITopUpPolicyPlans } from './types';
import { isPolicyStartDateResolverEnabled } from '../../../utils/featureFlags';
import { setTopupPolicyId } from '../../../topup-src/redux/slices/ApplicationStateSlice';

const ENROLMENT_ACTION_TYPES = {
    ADD_MEMBER: 'enrolmentSlice/add-member',
    DELETE_MEMBER: 'enrolmentSlice/delete-member',
    EDIT_MEMBER: 'enrolmentSlice/edit-member',
    FETCH_POLICY_PLAN: 'enrolmentslice/fetch-policy-plan',
    FETCH_POLICY_DETAILS: 'enrolmentslice/fetch-policy-details',
    TOP_UP_OPTIONS: 'enrolmentslice/fetch-top-up-options',
    UPDATE_ENROLMENT_STATUS: 'enrolmentslice/update-enrolment-status',
    UPDATE_TOP_UP_OPTIONS: 'enrolmentslice/update-topup-options',
    ADD_USER_TO_POLICY: 'enrolmentslice/add-user-to-policy',
    REMOVE_USER_FROM_POLICY: 'enrolmentslice/remove-user-from-policy',
    FETCH_SUPERTOPUP_AVAILABLE: 'enrolmentslice/fetch-super-topup-available',
    ENROLL_USER_FAMILY: 'enrolmentslice/enrol-user-policy-family'
};

export const fetchPolicyDetails = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.FETCH_POLICY_DETAILS,
    async ({ policyId }: { policyId: string }) => {
        const res = await fetchPolicyPreValidations(policyId);
        return {
            policyDetails: res
        };
    }
);
export const fetchTopUpAvailabiltyStatus = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.FETCH_SUPERTOPUP_AVAILABLE,
    async ({ userId }: { userId: string }, { dispatch }) => {
        try {
            const response: any = await fetchSuperTopupAvailableDetails(userId);
            if (response.data?.topup?.status != 'NOT_AVAILABLE') {
                dispatch(setTopupPolicyId({ topupPolicyId: response.data?.topup?.policyFamilyDetail.policyId }));
            }
            return {
                superTopUpPolicy: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const enrolUserPolicyFamily = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.ENROLL_USER_FAMILY,
    async ({
        userId,
        policyId,
        slabId,
        dependentIds,
        enrolmentStatus,
        policyStatus,
        parentalPolicyId
    }: {
        userId: string;
        policyId: string;
        slabId: string;
        enrolmentStatus: string;
        policyStatus: string;
        dependentIds: string[];
        parentalPolicyId: string;
    }) => {
        try {
            const req = {
                enrolmentCycle: 'At Inception',
                enrolmentStatus,
                policyStatus,
                generateEcardFlag: enrolmentStatus === 'ENROLED',
                dependentIds,
                slabId,
                active: enrolmentStatus === 'ENROLED',
                parentalPolicyId
            };
            const response = await enrolUserToPolicyFamily(req, userId, policyId);
            return {
                enrolUserFamily: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const fetchTopUpOptions = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.TOP_UP_OPTIONS,
    async ({ policyId }: { policyId: string }) => {
        const res = await fetchEnrolmentTopUpOptions(policyId);
        return {
            topUpOptions: res as ITopUpPolicyPlans[]
        };
    }
);
// edit details of existing member
export const editMember = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.EDIT_MEMBER,
    async (
        {
            dependent,
            selfId,
            policyId,
            companyId,
            selfEmail,
            enrolmentDueDate
        }: {
            dependent: IDependent;
            selfId: string;
            policyId: string;
            companyId: string;
            selfEmail: string;
            enrolmentDueDate: Date;
        },
        { dispatch }
    ) => {
        try {
            const editMemberRequest = {
                date_of_birth: new Date((dependent.doB as any) * 1000).toLocaleDateString('en-GB'),
                email_address: dependent.relationship === 'Self' ? selfEmail : '',
                gender: dependent.gender,
                mobile: dependent.mobile,
                policy_start_date: new Date().toLocaleDateString('en-GB'),
                name: `${dependent.firstName} ${dependent.lastName}`,
                relationship_to_account_holders: dependent.relationship,
                userId: dependent.userId,
                enrolment_due_date: enrolmentDueDate.toLocaleDateString('en-GB')
            };
            const response = await editMembersAPI(companyId, editMemberRequest);
            if ((response as any).data.failure.length) {
                throw new Error((response as any).data.failure[0].errorMsg);
            }
            const updatedCoverageAmount = await fetchSIAndAnnualPremium(selfId, policyId);
            const editedUser: ICRUDResponse = {
                userId: dependent.userId,
                policyId: policyId,
                firstName: dependent.firstName,
                lastName: dependent.lastName,
                relationship: dependent.relationship.toLowerCase(),
                mobile: dependent.mobile,
                gender: dependent.gender,
                doB: dependent.doB
            };
            dispatch(setShowEditModal(false));
            dispatch(
                updatePolicyList({
                    updatedUser: editedUser,
                    coverageAmount: updatedCoverageAmount
                })
            );
            return {
                crudDependents: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

// add new dependent to a new policy
export const addDependent = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.ADD_MEMBER,
    async (
        {
            dependent,
            selfEmployeeId,
            dynamicFields,
            policyId,
            companyId,
            selfId
        }: {
            dependent: IDependentBase;
            selfEmployeeId: string;
            policyId: string;
            dynamicFields: DynamicField;
            companyId: string;
            selfId: string;
        },
        { dispatch }
    ) => {
        try {
            const dateOfBirth = new Date(dependent.doB * 1000);

            // not accounting for dynamic fields
            if (dynamicFields) {
                // do nothing
            }
            const selfUserPolicy = await fetchSelfUserPolicy(selfId, policyId);
            const dependentAddRequest = {
                data_addition: selfUserPolicy.enrolmentCycle || '',
                date_of_birth: new Date((dependent.doB as any) * 1000).toLocaleDateString('en-GB'),
                email_address: '',
                enrolment_due_date:
                    new Date((selfUserPolicy.enrolmentDueDate as any)._seconds * 1000).toLocaleDateString(
                        'en-GB'
                    ) || new Date().toLocaleDateString('en-GB'),
                gender: dependent.gender,
                mobile: dependent.mobile,
                name: `${dependent.firstName} ${dependent.lastName}`,
                policy_start_date: isPolicyStartDateResolverEnabled
                    ? undefined
                    : new Date(selfUserPolicy.policyStartDate._seconds * 1000).toLocaleDateString('en-GB'),
                relationship_to_account_holders: dependent.relationship,
                slabId: selfUserPolicy.slabId || '',
                employee_id: selfEmployeeId,
                parentUserId: selfId
            };
            const response = await addMemberAPI(companyId, policyId, dependentAddRequest);
            if ((response as any).data.failure.length) {
                throw new Error((response as any).data.failure[0].errorMsg);
            }
            const userId = (response as any).data.success[0].userId;
            // const response = await addFamDependent(req);
            // await enrolUserToPolicy(selfId, (response as any).userId, policyId);
            const updatedCoverageAmount = await fetchSIAndAnnualPremium(selfId, policyId);
            const addedUser: ICRUDResponse = {
                userId: userId,
                policyId: policyId,
                firstName: dependent.firstName,
                lastName: dependent.lastName,
                relationship: dependent.relationship.toLowerCase(),
                mobile: dependent.mobile,
                gender: dependent.gender,
                doB: FirebaseTimestamp.fromDate(dateOfBirth).seconds
            };

            dispatch(setShowAddModal(false));

            // To update the new sumInsured in the Policy List
            dispatch(
                updatePolicyList({
                    updatedUser: addedUser,
                    coverageAmount: updatedCoverageAmount
                })
            );

            return {
                crudDependents: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const enrolSelectedUserToPolicy = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.ADD_USER_TO_POLICY,
    async (
        {
            selfId,
            userId,
            policyId,
            dependentDetails,
            companyId
        }: { selfId: string; userId: string; policyId: string; dependentDetails: IDependent; companyId: string },
        { dispatch }
    ) => {
        try {
            const selfUserPolicy = await fetchSelfUserPolicy(selfId, policyId);
            const response = await addExistingAPI(companyId, policyId, {
                data_addition: selfUserPolicy.enrolmentCycle || '',
                date_of_birth: new Date((dependentDetails.doB as any)._seconds * 1000).toLocaleDateString('en-GB'),
                email_address: '',
                enrolment_due_date:
                    new Date((selfUserPolicy.enrolmentDueDate as any)?._seconds * 1000).toLocaleDateString(
                        'en-GB'
                    ) || null,
                gender: dependentDetails.gender,
                mobile: dependentDetails.mobile,
                name: `${dependentDetails.firstName} ${dependentDetails.lastName}`,
                policy_start_date: new Date(selfUserPolicy.policyStartDate._seconds * 1000).toLocaleDateString(
                    'en-GB'
                ),
                relationship_to_account_holders: dependentDetails.relationship,
                slabId: selfUserPolicy.slabId || '',
                userId
            });
            if (((response as any).data[0] || []).length && (((response as any).data[0] || []).errors || '').length) {
                throw new Error((response as any).data.data[0].errors);
            }
            const dateOfBirth = new Date((dependentDetails.doB as any)._seconds * 1000);
            const updatedCoverageAmount = await fetchSIAndAnnualPremium(selfId, policyId);
            const addedUser = {
                userId: userId,
                policyId: policyId,
                firstName: dependentDetails.firstName,
                lastName: dependentDetails.lastName,
                relationship: dependentDetails.relationship.toLowerCase(),
                mobile: dependentDetails.mobile,
                gender: dependentDetails.gender,
                doB: FirebaseTimestamp.fromDate(dateOfBirth).seconds
            };
            dispatch(setShowAddModal(false));
            dispatch(
                updatePolicyList({
                    updatedUser: addedUser,
                    coverageAmount: updatedCoverageAmount
                })
            );
            return {
                crudDependents: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const updateSelectedTopUp = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.UPDATE_TOP_UP_OPTIONS,
    async (
        {
            topUpDetails,
            userId,
            policyId
        }: {
            topUpDetails: IMemberTopUp[] | null;
            userId: string;
            policyId: string;
        },
        { dispatch, getState, rejectWithValue }
    ) => {
        try {
            await saveEnrolmentTopUpOptions(topUpDetails, userId, policyId);
            const updateResponse = await updateUserPolicyEnrolmentStatus(userId, policyId);
            dispatch(fetchUserPolicyList({ userId }));
            if (updateResponse === 'Enroled') {
                const {
                    enrolment: { currentlySelectedPolicy }
                } = getState() as {
                    enrolment: { currentlySelectedPolicy: IPolicy };
                };
                dispatch(
                    updatePolicyList({
                        updatedPolicy: {
                            ...currentlySelectedPolicy,
                            enrolmentStatus: updateResponse,
                            topUpAdded: topUpDetails
                                ? topUpDetails.map((slab) => slab.sumInsured).reduce((prev, curr) => prev + curr, 0)
                                : 0
                        }
                    })
                );
                return {
                    updatedPolicy: {
                        ...currentlySelectedPolicy,
                        enrolmentStatus: updateResponse
                    },
                    updateTopUpOption: {
                        data: true
                    }
                };
            } else {
                return rejectWithValue({ message: 'Unable to enrol at this time. Please try again later.' });
            }
        } catch (e: any) {
            const message = e?.message ?? 'Failed!';
            return rejectWithValue({ message });
        }
    }
);

export const removeSelectedUserFromPolicy = createAsyncThunk(
    ENROLMENT_ACTION_TYPES.REMOVE_USER_FROM_POLICY,
    async (
        {
            userId,
            policyId,
            companyId,
            selfId,
            relationship
        }: {
            userId: string;
            policyId: string;
            companyId: string;
            selfId: string;
            relationship: string;
        },
        { dispatch }
    ) => {
        try {
            await offboardMemberAPI({
                companyId,
                userId,
                policyId,
                dateOfLeaving: new Date(),
                relationship
            });
            const response: ICRUDResponse = {
                userId: userId,
                policyId: policyId,
                firstName: '',
                lastName: '',
                relationship: relationship,
                mobile: '',
                gender: '',
                doB: 212325615
            };
            const updatedCoverageAmount = await fetchSIAndAnnualPremium(selfId, policyId);
            dispatch(setShowDeleteModal(false));
            dispatch(
                updatePolicyList({
                    updatedUser: response,
                    coverageAmount: updatedCoverageAmount
                })
            );

            return {
                crudDependents: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);
