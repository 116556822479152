import React from 'react';
import { StyledContainer } from './styles';
import { ConfirmationCardDetails, InsuranceIdentity } from '../../atoms';
import { IConfirmationCard } from './types';

const ConfirmationCard: React.FunctionComponent<IConfirmationCard> = ({
    policyCategory,
    policyImage,
    policyName,
    premiumToPay,
    sumInsured,
    topUpAmount,
    coveredMembers,
    familyStructure,
    annualPremium,
    policyEndDate
}) => {
    return (
        <StyledContainer>
            <InsuranceIdentity
                policyCategory={policyCategory}
                policyImage={policyImage}
                policyName={policyName}
                familyStructure={familyStructure}
            />
            <ConfirmationCardDetails
                coveredMembers={coveredMembers}
                premiumToPay={premiumToPay}
                sumInsured={sumInsured}
                topUpAmount={topUpAmount}
                policyCategory={policyCategory}
                annualPremium={annualPremium}
                policyEndDate={policyEndDate}
            />
        </StyledContainer>
    );
};

export default ConfirmationCard;
