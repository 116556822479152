import React from 'react';
import { StyledContainer } from './styles';
import { STEPPER_META, STEPPER_META_SUPER_TOPUP, STEPPER_META_NO_TOP_UP } from '../../../utils/constants';
import StepperStep from '../StepperStep';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import { setCurrentStepperStep } from '../../../redux/slices/ApplicationStateSlice';
import { checkIfTopUpAvailable } from '../../../utils/common';
import { useHistory, useLocation } from 'react-router-dom';
import { IPolicy } from '../../../redux/slices/PolicyListSlice/types';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { checkTopUpAvailable } from '../../../utils/PolicyServices';
import usePolicyTopUpConfigurationCheck from '../../../utils/hooks/enrolment/usePolicyTopUpConfigurationCheck';

export const Stepper: React.FunctionComponent = () => {
    const history = useHistory();
    const location = useLocation();
    const policyList: IPolicy[] = useSelector((state: ReduxState) => state.policyList.policyList.data) || [];
    const currentlySelectedPolicy = useSelector((state: ReduxState) => state.enrolment.currentlySelectedPolicy);
    const checkSuperTopUp = checkTopUpAvailable();
    const superTopUp = checkIfTopUpAvailable(checkSuperTopUp, currentlySelectedPolicy);
    const dispatch = useDispatch();
    const currentSelectedStep = useSelector((state: ReduxState) => state.applicationState.currentStepperStep);
    const topUpOptions = useSelector((state: ReduxState) => state.enrolment.topUpOptions);
    const isTopUpConfigured = usePolicyTopUpConfigurationCheck();
    const compTopUp = !topUpOptions.loading ? (topUpOptions.data?.length ? true : false) : true;
    const stepperMetaData =
        superTopUp &&
        (currentlySelectedPolicy?.policyType === 'GMC' || currentlySelectedPolicy?.policyType === 'SUPER_TOP_UP')
            ? STEPPER_META_SUPER_TOPUP
            : compTopUp && isTopUpConfigured
                ? STEPPER_META
                : STEPPER_META_NO_TOP_UP;
    const trackClick = useSegment('click');
    const segmentAction = SEGMENT_ACTIONS.CLICK.ENROLMENT_STEPPER_STEP;

    const getGMCPolicy = () => (policyList ? policyList.find((policy: IPolicy) => policy.policyType === 'GMC') : null);
    const changeStepper = (stepId: number) => {
        if (currentSelectedStep < stepId) {
            return;
        }
        if (getGMCPolicy()?.policyType === 'GMC' && getGMCPolicy()?.enrolmentStatus === 'ENROLED' && stepId === 1) {
            return;
        }
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: segmentAction.properties?.describe || '',
                step: (segmentAction.properties?.step as (step: number) => number)(stepId) || '',
                login_status: segmentAction.properties?.login_status,
                page_title: (segmentAction.properties?.page_title as (pageTitle: string) => string)(
                    stepperMetaData[stepId].name
                )
            }
        });
        dispatch(setCurrentStepperStep({ currentStepperStep: stepId }));
        history.push(stepperMetaData[stepId - 1].stepUrl ?? '', { previousUrl: location.pathname });
    };
    const checkForNull = (stepId: number) => {
        if (stepId === 2) {
            if (compTopUp || superTopUp) {
                return true;
            }
            return false;
        }
        return true;
    };
    return (
        <StyledContainer>
            {stepperMetaData.map((step) =>
                checkForNull(step.id) ? (
                    <StepperStep
                        key={'EnrolmentStepper' + step.id}
                        id={step.id}
                        description={step.description}
                        name={step.name}
                        image={step.image}
                        setStep={changeStepper}
                        isActive={currentSelectedStep === step.id}
                        isDone={currentSelectedStep > step.id}
                        stepCountRatio={`${step.id}/${stepperMetaData.length - 1}`}
                    />
                ) : null
            )}
        </StyledContainer>
    );
};

export default Stepper;
